/* custom-bootstrap.css */
@media (min-width: 576px) {
    .custom-container {
        width: 100%; /* Small screens */
    }
}

@media (min-width: 768px) {
    .custom-container {
        width: 100%; /* Medium screens */
    }
}

@media (min-width: 992px) {
    .custom-container {
        width: 100%; /* Large screens */
        max-width: 1200px; /* Set max width for large screens */
    }
}

@media (min-width: 1200px) {
    .custom-container {
        width: 100%; /* Extra large screens */
        max-width: 1150px; /* Adjust max width for extra large screens */
    }
}

@media (min-width: 1400px) {
    .custom-container {
        width: 100%; /* Extra large screens */
        max-width: 1200px; /* Set max width for larger than extra large */
    }
}
