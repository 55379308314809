/* index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}

.arimo-regular {
  font-family: "Arimo", sans-serif;
  font-weight: 400; /* Regular weight */
}

.arimo-bold {
  font-family: "Arimo", sans-serif;
  font-weight: 700; /* Bold weight */
}

a {
  font-family: "Arimo", sans-serif;
}





.chat-container {
  width: 400px;
  height: 500px;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
}

.chat-box {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  padding: 8px;
  margin: 5px;
  border-radius: 5px;
  max-width: 80%;
}

.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.bot {
  background-color: #f1f1f1;
  color: black;
  align-self: flex-start;
}

.input-box {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.input-box input {
  flex-grow: 1;
  padding: 8px;
}

.input-box button {
  padding: 8px;
  cursor: pointer;
}
